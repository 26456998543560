import {api} from '../../../store/api';
import {
    ByCourier,
    ByRangeData,
    BySource,
    ByStage,
    MainData,
    ObjectPoint,
    OrderShort,
    OrderToday,
    OrderTodayArr,
    Point
} from './types';

const roundByValues = (byCourier: any): any => {
    const roundedCourier = {...byCourier};
    const exclude = ['upt', 'uptm'];
    Object.keys(roundedCourier).forEach((key) => {
        if (exclude.includes(key)) return;

        roundedCourier[key as keyof any] = Math.round(roundedCourier[key as keyof any]);
    });

    return roundedCourier;
};

const roundBySourceValues = (byCourier: BySource): any => {
    const roundedCourier = {...byCourier};
    Object.keys(roundedCourier).forEach((key) => {
        if (!roundedCourier[key as keyof BySource].sum) return;
        roundedCourier[key as keyof BySource].sum = Math.round(roundedCourier[key as keyof BySource].sum);
    });

    return roundedCourier;
};

const sortOrder = ['Waiting', 'WaitCooking', 'CookingStarted', 'CookingCompleted', 'OnWay', 'Delivered', 'Closed', 'Cancelled'];


const sortOrderTodayFn = (data: any): OrderTodayArr => {
    const first: OrderToday[] = [];
    const second: OrderToday[] = [];
    const third: OrderToday[] = [];
    const fourth: OrderToday[] = [];
    const fifth: OrderToday[] = [];

    const sortOrderToday = [
        ['OrdersAll', 'OrdersWithoutDelivery', 'OrdersWithDelivery', 'OrdersCancelled', 'OrdersClosed'],
        ['OrdersInWork', 'OrdersOnWay'],
        ['OrdersOverdued', 'OrdersOverduedByHour'],
        ['AvgCooking', 'AvgTimeSended', 'AvgClickToEat'],
        ['CourierInWork', 'CntCouriers', 'CntPzMakers', 'FurnacePower']
    ];

    sortOrderToday[0].forEach(key => first.push(data[key]));
    sortOrderToday[1].forEach(key => second.push(data[key]));
    sortOrderToday[2].forEach(key => third.push(data[key]));
    sortOrderToday[3].forEach(key => fourth.push(data[key]));
    sortOrderToday[4].forEach(key => fifth.push(data[key]));

    return {
        first,
        second,
        third,
        fourth,
        fifth
    };
};

function sortByCustomOrder(data: OrderToday[], sortOrder: string[]) {
    const orderMap = new Map(sortOrder.map((name, index) => [name, index])) as any;

    data.sort((a, b) => orderMap.get(a.name_en) - orderMap.get(b.name_en));

    return data;
}

export const monitoringApi = api.injectEndpoints({
    endpoints: build => ({
        getMainData: build.query<MainData, { params: string }>({
            query: ({params}) => ({
                url: `/reports/keyRevenueIndicatorsToday.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getByRange: build.query<ByRangeData, { params: string }>({
            query: ({params}) => ({
                url: `/reports/keyRevenueIndicators.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getBySource: build.query<BySource, { params: string }>({
            query: ({params}) => ({
                url: `/reports/keyRevenueIndicatorsBySource.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: BySource) => {
                return roundBySourceValues(data);
            }
        }),
        getByCourier: build.query<ByCourier, { params: string }>({
            query: ({params}) => ({
                url: `/reports/revenueAnalysisCouriers.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getByStage: build.query<ByStage, { params: string }>({
            query: ({params}) => ({
                url: `/reports/avgTimeValue.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByStage) => {
                return roundByValues(data);
            }
        }),
        getObjectInMap: build.query<ObjectPoint[], { params: string }>({
            query: ({params}) => ({
                url: `/listData/listObjectsWithCoordinates.php?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({list}: { list: ObjectPoint[] }) => {
                return list.filter(i => i.lat !== null && i.lon !== null);
            }
        }),
        getPointAll: build.query<Point[], { params: string }>({
            query: ({params}) => ({
                url: `/orderHooks/orderMapData.php?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({heatmapData}) => {
                return heatmapData;
            }
        }),
        orderQueryToday: build.query<OrderTodayArr, { params: string }>({
            query: ({params}) => ({
                url: `/reports/orderQueueToday.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                // first second third fourth fifth
                return sortOrderTodayFn(data);
            }
        }),
        orderShort: build.query<OrderShort[], { params: string }>({
            query: ({params}) => ({
                url: `/orderHooks/p_OrderHook_shortTable.php?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: any) => {
                return data.table.body.filter((i: OrderShort) => i.ID_Obj !== null);
            }
        }),
        getOrderCount: build.query<OrderToday[], { params: string }>({
            query: ({params}) => ({
                url: `/orderHooks/p_OrderHook_resultValue.php?${params}`
            }),
            transformResponse: (data: any) => {
                const items = Object.values(data).filter(i => typeof i !== 'boolean') as OrderToday[];
                return sortByCustomOrder(items, sortOrder);
            }
        })
    }), overrideExisting: true
});

export const {
    useLazyGetMainDataQuery,
    useLazyGetByRangeQuery,
    useLazyGetBySourceQuery,
    useLazyGetByCourierQuery,
    useLazyGetByStageQuery,
    useLazyGetObjectInMapQuery,
    useLazyGetPointAllQuery,
    useLazyOrderQueryTodayQuery,
    useLazyOrderShortQuery,
    useLazyGetOrderCountQuery
} = monitoringApi;
