import React, {useEffect, useRef, useState} from 'react';
import styles from '../styles.module.scss';
import {useAppSelector} from '../../../hooks/app.hooks';
import {Paragraph} from '../components';
import {Map} from '@pbe/react-yandex-maps';
import classNames from 'classnames';
import {ZoomIn, ZoomOut} from '../../../assets/svg';
import {themColors} from '../../../hooks/useChangeTheme';
import dayjs from 'dayjs';

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const splitTime = (date: string) => {
    if (!date) return '';
    return date.split(' ')[1];
};

const getTimeRange = (date1: string, date2: string) => {
    if (!date1 || !date2) return '';

    const d1 = dayjs(date1, 'DD.MM.YYYY HH:mm');
    const d2 = dayjs(date2, 'DD.MM.YYYY HH:mm');
    const totalMinutes = d2.diff(d1, 'minute');
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours === 0) {
        return `${minutes} мин`;
    }

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

export const Delivery = () => {
    const mapRef = useRef<any>(null);
    const {theme} = useAppSelector(state => state.app);

    const [ymaps, setYmaps] = useState<any>();
    const {order} = useAppSelector(state => state.order);
    const [carTime, setCarTime] = useState<string>('');
    const [bikeTime, setBikeTime] = useState<string>('');
    const [pedestrianTime, setPedestrianTime] = useState<string>('');
    const [zoom, setZoom] = useState(16);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }

    }, [zoom]);

    useEffect(() => {
        if (ymaps && mapRef.current && order && order.address['2']) {
            const address = [order.address['2'].coordinates.latitude, order.address['2'].coordinates.longitude];
            const object = [+order.objectInfo.Latitude, +order.objectInfo.Longitude];


            const carRoute = new ymaps.multiRouter.MultiRoute({
                    referencePoints: [
                        object, // Первая точка
                        address // Вторая точка
                    ],
                    params: {
                        // Параметры для первого маршрута
                        routingMode: 'auto', // Для автомобиля
                        avoid: [],
                        avoidTrafficJams: true,
                        results: 1
                    }
                }
                // , {
                //     boundsAutoApply: true
                // }
            );


            mapRef.current.geoObjects.add(carRoute);

            // Создаем мультимаршрут для велосипеда
            const bikeRoute = new ymaps.multiRouter.MultiRoute({
                    referencePoints: [
                        object, // Первая точка
                        address // Вторая точка
                    ],
                    params: {
                        routingMode: 'bicycle', // Для велосипеда
                    }
                }
                // , {
                //     boundsAutoApply: true
                // }
            );
            mapRef.current.geoObjects.add(bikeRoute);

            // Пример для пешеходного маршрута
            const pedestrianRoute = new ymaps.multiRouter.MultiRoute({
                    referencePoints: [
                        object, // Первая точка
                        address // Вторая точка
                    ],
                    params: {
                        routingMode: 'pedestrian', // Для пешеходов
                    }
                }
                // , {
                //     boundsAutoApply: true
                // }
            );

            // Добавляем пешеходный маршрут на карту
            mapRef.current.geoObjects.add(pedestrianRoute);

            // Обработчик события для мультимаршрута
            carRoute.model.events.add('requestsuccess', () => {
                const activeRoute = carRoute.getActiveRoute();
                if (activeRoute) {
                    const duration = activeRoute.properties.get('duration').text;
                    setCarTime(duration);
                }
            });

            // Обработчик события для велосипедного маршрута
            bikeRoute.model.events.add('requestsuccess', () => {
                const activeRoute = bikeRoute.getActiveRoute();
                if (activeRoute) {
                    const duration = activeRoute.properties.get('duration').text; // Время в пути на велосипеде

                    setBikeTime(duration);
                }
            });

            // Обработчик события для пешеходного маршрута
            pedestrianRoute.model.events.add('requestsuccess', () => {
                const activeRoute = pedestrianRoute.getActiveRoute();
                if (activeRoute) {
                    const duration = activeRoute.properties.get('duration').text;

                    setPedestrianTime(duration);
                }
            });
        }
    }, [ymaps, order]);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19); // Максимальный зум - 19
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0); // Минимальный зум - 0
    };


    if (!order) {
        return <></>;
    }

    const factDelivery = getTimeRange(order.orderInfo.DateSended, order.orderInfo.DateDelivered);

    return (
        <div className={styles.tab}>
            <Paragraph text={order.address['1'] ?? '---'} isBold={false} subtext={'Адрес:'}/>
            <span className={styles.tabSeparator}/>
            <Paragraph text={order.courier.name ?? '---'} isBold={false} subtext={'Курьер:'}/>
            <Paragraph text={order.courier.phone ?? '---'} isBold={false} subtext={'Тел:'}/>
            <Paragraph text={splitTime(order.orderInfo.DateSended) ?? '---'} isBold={false}
                       subtext={'Время отправки:'}/>
            <Paragraph text={carTime} isBold={false} subtext={'Время в пути на автомобиле:'}/>
            <Paragraph text={bikeTime} isBold={false} subtext={'Время в пути на велосипеде:'}/>
            <Paragraph text={pedestrianTime} isBold={false} subtext={'Время в пути пешком:'}/>
            <Paragraph text={splitTime(order.orderInfo.DateDelivered)} isBold={false} subtext={'Доставлено:'}/>
            <Paragraph text={String(factDelivery)} isBold={false} subtext={'Доставлено за:'}/>

            <div className={styles.map}>
                <button className={classNames(styles.button, styles.zoomIn)} onClick={handleZoomIn}>
                    <ZoomIn color={themColors[theme].color}/>
                </button>
                <button className={classNames(styles.button, styles.zoomOut)} onClick={handleZoomOut}>
                    <ZoomOut color={themColors[theme].color}/>
                </button>
                <Map
                    defaultState={{zoom, center: [+order.objectInfo.Latitude, +order.objectInfo.Longitude]}}
                    width={window.innerWidth - 60}
                    height={400}
                    instanceRef={mapRef}
                    onLoad={ymaps => {
                        setYmaps(ymaps);
                    }}
                />
            </div>

        </div>
    );
};
