import React from 'react';
import Header from '../header';
import {BackButton} from '../backButton';
import styles from './style.module.scss';
import {ListMenu} from '../../screens/home/store/types';
import SimpleButton from '../simpleButton';
import {ImageLayout, MainLayout} from '../../layouts';
import {useNavigate} from 'react-router-dom';
import Footer from '../footer';
import AppLoader from '../appLoader';
import {ChartUser} from '../../assets/customSvg';
import {themColors} from '../../hooks/useChangeTheme';
import {useAppSelector} from '../../hooks/app.hooks';

interface ListButtonsProps {
    title: string;
    isLoading?: boolean;
    buttons: ListMenu[];
    handleClick: (item: ListMenu) => void;
}

export const ListButtons = (
    {
        title,
        buttons,
        isLoading,
        handleClick
    }: ListButtonsProps
) => {
    const navigate = useNavigate();
    const {theme} = useAppSelector(state => state.app);
    const onClick = (item: ListMenu) => {
        if (item.typeMob) {
            handleClick(item);
        } else {
            navigate('/empty-page', {state: {text: item.textMob}});
        }
    };
    if (isLoading) {
        return <AppLoader isFull={true}/>;
    }
    return (
        <MainLayout>
            <Header breadCrumbs={title}/>
            <BackButton text={'Назад на главную'} onClick={() => navigate('/')}/>
            <div className={styles.list}>

                {buttons.map((item: ListMenu) => {
                    return (
                        <SimpleButton
                            key={item.idForm}
                            column={2}
                            handler={() => onClick(item)}
                        >
                            <div className={styles.listItem}>
                                {
                                    item.icon.type === 'iconsvg'
                                        ? <ChartUser color={themColors[theme].color} width={34} height={34}
                                                     fileName={item.icon.source as string}/>
                                        : (
                                            <ImageLayout
                                                src={item.icon.source}
                                                imageWidth={32}
                                                imageHeight={32}
                                                containerWidth={32}
                                                containerHeight={32}
                                            />
                                        )
                                }
                                <span className={styles.listText}>{item.name}</span>
                            </div>
                        </SimpleButton>
                    );
                })}
            </div>
            <Footer/>
        </MainLayout>
    );
};
